import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import { getNomePagina } from '../../shared/utils/Utils';
import { getToken } from '../../authentication/Auth';
import { menus } from '../../shared/constants/MenuConstants';
import { getConfiguracaoGeral } from '../configuracoes/configuracoes-gerais/ConfiguracoesGerais.service';

const PATH_MENU = menus.COD_17720;

function MonitoramentoDeIntegracoes() {
  const [url, setUrl] = useState('');
  const [token] = useState(getToken());

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getConfiguracaoGeral([619]);

      if (data && data.data && data.data.valor) {
        setUrl(data.data.valor);
      }

    };

    fetchData();
  }, []);

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
      <div className='row'>
        <Iframe
          url={`${url}/monitoramento?token=${token}`}
          className='iframePop'
        />
      </div>
    </LinxPostos>
  );
}

export default MonitoramentoDeIntegracoes;
