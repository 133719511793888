import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import { getDadosLogin, maskedCnpj } from '../../../../shared/utils/Utils';

export class ExportarParaExcelService {
  async execute(e, title, filters, fileName, configure = null) {
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === dadosLogin.cnpj);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    if(configure)configure(worksheet)

    return exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {
        row: 4,
        column: 1,
      },
    })
      .then(cellRange => {
        worksheet.mergeCells(1, 1, 1, worksheet.columnCount);
        worksheet.mergeCells(2, 1, 2, worksheet.columnCount);
        worksheet.mergeCells(3, 1, 3, worksheet.columnCount);

        //Header
        const headerRow = worksheet.getRow(1, 2);
        headerRow.getCell(2).value = title;
        headerRow.height = 25;
        headerRow.getCell(2).font = {
          size: 18,
        };
        headerRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        //Subtitle
        const headerSubRow = worksheet.getRow(2, 2);
        headerSubRow.height = 25;
        headerSubRow.getCell(2).value = `CNPJ: ${maskedCnpj(empresa.cnpj)} - ${empresa.nomeFantasia}`;
        headerSubRow.getCell(2).font = {
          size: 14,
        };
        headerSubRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        //Filters
        const filtersRow = worksheet.getRow(3, 2);
        filtersRow.height = 25;
        filtersRow.getCell(2).value = filters;
        filtersRow.getCell(2).font = {
          size: 14,
        };
        filtersRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        // footer Time
        const footerRow = cellRange.to.row + 2;

        const footerTimeRow = worksheet.getRow(footerRow, 1);
        footerTimeRow.getCell(1).value = moment(new Date()).format('DD/MM/YYYY HH:mm');
        footerTimeRow.getCell(1).font = {
          italic: true,
        };

        // footer URL
        const footerUrlRow = worksheet.getRow(footerRow, 1);
        worksheet.mergeCells(footerRow, 2, footerRow, worksheet.columnCount - 1);
        footerUrlRow.getCell(2).value = dadosLogin.tipoSiteUrl;
        footerUrlRow.getCell(2).font = {
          italic: true,
        };
        footerUrlRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        // footer User
        const footerUserRow = worksheet.getRow(footerRow, 1);
        footerUserRow.getCell(worksheet.columnCount).value = dadosLogin.nome;
        footerUserRow.getCell(worksheet.columnCount).font = {
          italic: true,
        };

        worksheet.autoFilter = {
          from: {
            row: 4,
            column: 1,
          },
          to: {
            row: cellRange.to.row,
            column: worksheet.columnCount,
          },
        };
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        });
      });
  }
}
