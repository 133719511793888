import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Botao from '../../../../components/botao/Botao';
import modalclose from '../../../../../assets/images/postopop/modal-close.png';
import notify from 'devextreme/ui/notify';
import { FloatLabelInput } from './FloatLabelInput';
import { FloatDateInput } from './FloatDateInput';
import { Column, DataGrid } from 'devextreme-react/data-grid';
import { promocoesScantechService } from '../promocoesScantech.service';
import { Tooltip } from 'devextreme-react/tooltip';

import styles from '../promocoesScantech.module.scss';
import { Button } from 'devextreme-react';

export const ModalDetailPromotion = ({ active, handleCloseModal, selectPromotionsGrid, setSearching }) => {
  const [data, setData] = useState({
    idPromocao: 0,
    tipoPromocao: '',
    status: '',
    descricao: '',
    autor: '',
    periodo: '',
    limitePromocao: 0,
    preco: 0,
    desconto: 0,
    valorPagar: 0,
    dataAtivacao: new Date().toISOString(),
    dataRejeicao: new Date().toISOString(),
    listaCondicional: [],
    listaCondicaoPagamento: [],
    listaCondicaoPagamentoBin: [],
    beneficiosScantech: [],
  });

  const getPromotion = async () => {
    try {
      setSearching(true);
      const idPromotion = selectPromotionsGrid[0];
      const response = await promocoesScantechService.getDetailsPromotion(idPromotion);

      console.log('Resultado: ', response);

      if (response != null && response.notifications != null && response.notifications.length > 0) {
        var qtdNotify = response.notifications.length;
        var msg = '';

        for (var i = 0; i < qtdNotify; i++) {
          msg += response.notifications[i].message + '\n';
        }

        notify(msg, 'error', 5000);
      } else {
        if (response && response.result) {
          setData(response.result);
        } else {
          notify(response.message, 'error', 5000);
        }
      }
    } catch (error) {
      notify('Ocorreu um erro interno. Já estamos atuando.', 'error', 2000, 'top');
    } finally {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (active) {
      getPromotion();
    }
  }, [active]);

  return (
    <Modal size="lg" show={active} onHide={() => handleCloseModal(false)} className={`${active ? 'active' : null}`} centered>
      <Modal.Header>
        <Modal.Title>Detalhes da Promoção</Modal.Title>
        <img src={modalclose} alt="Fechar modal" className="modal-close" onClick={() => handleCloseModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <h6 className={`pb-2 ${styles.textBodySecondary}`}>
                Para sincronizar e realizar a venda da promoção no ponto de venda (PDV), os produtos vinculados à promoção devem estar ativos no cadastro de produtos.
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="text" value={data.idPromocao} onChange={() => {}} id="idPromocao" label="Id Promoção" />
            </Col>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="text" value={data.tipoPromocao} onChange={() => {}} id="tipoPromocao" label="Tipo Promoção" />
            </Col>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="text" value={data.status} onChange={() => {}} id="statusPromocao" label="Status Promoção" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatLabelInput readonly={true} type="text" value={data.descricao} onChange={() => {}} id="descricao" label="Descrição Promoção" />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="text" value={data.autor} onChange={() => {}} id="autor" label="Autor" />
            </Col>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="text" value={data.periodo} onChange={() => {}} id="periodoVigencia" label="Periodo Vigência da Promoção" />
            </Col>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="number" value={data.limitePromocao.toFixed(0)} onChange={() => {}} id="limitePorCupom" label="Limite de Promoções por cupom" />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="number" value={data.preco.toFixed(2)} onChange={() => {}} id="preco" label="Preço final da promoção" />
            </Col>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="number" value={data.desconto.toFixed(2)} onChange={() => {}} id="desconto" label="Desconto a ser aplicado" />
            </Col>
            <Col xs={4}>
              <FloatLabelInput readonly={true} type="number" value={data.valorPagar.toFixed(2)} onChange={() => {}} id="pagar" label="Quantidade de itens a pagar" />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <FloatDateInput
                displayFormat="dd/MM/yyyy"
                //placeholder="data"
                label="Data Ativação"
                value={data.dataAtivacao}
                id="dtAtivacao"
                onChange={() => {}}
                readonly={true}
              />
            </Col>
            <Col xs={4}>
              <FloatDateInput
                displayFormat="dd/MM/yyyy"
                //placeholder="data"
                label="Data Rejeição"
                value={data.dataRejeicao}
                id="dtRejeicao"
                onChange={() => {}}
                readonly={true}
              />
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Tabs defaultActiveKey="condicionais" id="uncontrolled-tab" className="mb-3">
            <Tab eventKey="condicionais" title="Itens Condicionais">
              <div className={`${styles.table}`}>
                <DataGrid
                  rowAlternationEnabled={true}
                  dataSource={data.listaCondicional}
                  noDataText="Sem dados"
                  id="gridCondicionais"
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  className={`${styles.middle}`}
                >
                  <Column
                    dataField="codigoProdutoScantech"caption="Código Produto"></Column>
                  <Column
                    dataField="descricao"
                    caption="Descrição Produto"
                    cellRender={cellData => (
                      <div>
                        <Tooltip
                          target={`#tooltip-${cellData.rowIndex}`} // Vincula o tooltip ao item baseado no índice da linha
                          showEvent="mouseenter" // Exibe ao passar o mouse
                          hideEvent="mouseleave" // Esconde ao remover o mouse
                        >
                          <div>{cellData.value}</div> {/* Conteúdo do tooltip */}
                        </Tooltip>
                        <div id={`tooltip-${cellData.rowIndex}`}>{cellData.value}</div> {/* Conteúdo da célula */}
                      </div>
                    )}
                  ></Column>
                  <Column dataField="quantidade" width={90} caption="Quantidade"></Column>
                  <Column dataField="produtoCadastro" width={200} caption="Produto Cadastrado" />
                  <Column dataField="statusProduto" width={175} caption="Status Produto" />
                </DataGrid>
              </div>
            </Tab>
            <Tab eventKey="pagamentos" title="Condições de Pagamentos">
              <Row>
                <Col>
                  <div className={`${styles.table}`}>
                    <DataGrid
                      rowAlternationEnabled={true}
                      dataSource={data.listaCondicaoPagamento}
                      noDataText="Sem dados"
                      id="gridPagamento"
                      allowColumnReordering={true}
                      remoteOperations={true}
                      className={`${styles.middle}`}
                    >
                      <Column dataField="tipoPagamento" width={100} caption="Tipo Pagamento"></Column>
                      <Column dataField="descricaoPagamento" minWidth={80} caption="Descrição"></Column>
                    </DataGrid>
                  </div>
                </Col>
                <Col>
                  <div className={`${styles.table}`}>
                    <DataGrid
                      rowAlternationEnabled={true}
                      dataSource={data.listaCondicaoPagamentoBin}
                      noDataText="Sem dados"
                      id="gridCdPagamento"
                      allowColumnReordering={true}
                      remoteOperations={true}
                      className={`${styles.middle}`}
                    >
                      <Column dataField="tipoPagamento" width={100} caption="Código Pagamento"></Column>
                      <Column dataField="bin" minWidth={80} caption="Bin"></Column>
                    </DataGrid>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="Beneficios" title="Benefício">
              <div className={`${styles.table}`}>
                <DataGrid
                  rowAlternationEnabled={true}
                  dataSource={data.beneficiosScantech}
                  noDataText="Sem dados"
                  id="gridBeneficios"
                  allowColumnReordering={true}
                  remoteOperations={true}
                  className={`${styles.middle}`}
                >
                  <Column dataField="codigoProdutoScantech" caption="Código Produto"></Column>
                  <Column dataField="descricaoProduto" caption="Descrição"></Column>
                  <Column dataField="quantidade" width={90} caption="Quantidade"></Column>
                  <Column dataField="produtoCadastro" width={200} caption="Produto Cadastrado" />
                  <Column dataField="statusProduto" width={175} caption="Status Produto" />
                </DataGrid>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          secondary
          className={'btn btn-secondary'}
          onClick={() => {
            handleCloseModal(false);
          }}
        >Voltar</Button>
      </Modal.Footer>
    </Modal>
  );
};
