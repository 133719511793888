import axios from 'axios';
import { getToken } from '../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

export const promocoesScantechService = {
  getPromotions,
  getNewPromotions,
  getDetailsPromotion,
  acceptPromotions,
  rejectPromotions,
  find,
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function getPromotions(appliedFilters){
  const data = appliedFilters;

  const response = await urlLinxCore
    .post('/Scantech', data)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return response;
}

async function getNewPromotions(){
  const response = await urlLinxCore
    .get('/Scantech/novas-promocoes')
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return response;
}

async function getDetailsPromotion(idPromotion){
  const response = await urlLinxCore
    .get('/Scantech/Detalhar', {
      params: {
        id_promocao: idPromotion
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return response;
}

async function acceptPromotions(data){
  const response = await urlLinxCore
    .post('/Scantech/Aceitar', data)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return response;
}

async function rejectPromotions(data){
  const response = await urlLinxCore
    .post('/Scantech/Rejeitar', data)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return response;
}

async function find(appliedFilters) {
  const response = await urlLinxCore
    .post('/Vendas/VendasCombustivelPremmia', { query: appliedFilters })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}


export default urlLinxCore;
