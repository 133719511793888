import React from 'react';
import styles from './Component.module.scss';

export function FloatLabelInput({ className, value, onChange, id, label, type, readonly = false }) {
  return (
    <div className={`form-group input-group mb-0 pb-1`}>
      <span className="has-float-label w-100" onClick={() => {
        document.getElementById(`frmNumeroOs_${id}`).focus();
      }}>
        <input
          className={`${className} form-control border-top-0 border-left-0 border-right-0`}
          type={type || 'text'}
          value={value || ''}
          id={`frmNumeroOs_${id}`}
          onChange={e => onChange({fieldName: id, value:  e.target.value})}
          placeholder={' '}
          readonly={readonly}
        />
        <label className={`${styles.pLaceholder}`} htmlFor={`frmNumeroOs_${id}`}>{label}</label>
      </span>
    </div>
  );
}
