import React from 'react';
import styles from '../promocoesScantech.module.scss';

export function Header({ onClickAddPromotion }) {
  return (
    <div className={`d-flex row ${styles.header}`}>
      <div className="col col-12">
        <h2 className="float-left ml-3 mt-3">Módulo Integração Scanntech</h2>
        <button style={{ width: '250px' }} className={`btn btn-primary float-right h-100`} onClick={onClickAddPromotion}>
          <span>BUSCAR NOVAS PROMOÇÔES</span>&nbsp;&nbsp;
        </button>
      </div>
    </div>
  );
}
