import { LoadPanel } from 'devextreme-react';
import { Column, DataGrid, Export, Pager, Paging, Selection } from 'devextreme-react/data-grid';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import ptMessages from '../../../../assets/js/pt.json';

import { Header } from './components/Header';
import { DropboxStatus, statusPromotions } from './components/DropboxStatus';
import { FloatDateInput } from './components/FloatDateInput';
import { FloatLabelInput } from './components/FloatLabelInput';
import { ModalDetailPromotion } from './components/Modal';
import { ExportarParaExcelService } from './services/ExportarParaExcell.service';
import { ExportarParaPdfService } from './services/ExportarParaPdf.service';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../shared/utils/Utils';
import styles from './promocoesScantech.module.scss';
import { promocoesScantechService } from './promocoesScantech.service';

export function PromocoesScantech() {
  const PATH_MENU = menus.COD_17900;
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [selectPromotionsGrid, setSelectPromotionsGrid] = useState([]);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchingMessage, setSearchingMessage] = useState('Carregando Pedidos...');

  const getFirstDayOfCurrentMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
  };

  const [startDate] = useState(getFirstDayOfCurrentMonth());
  const [endDate] = useState(new Date().toISOString());
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [title, setTitle] = useState([]);
  const [resolution, setResolution] = useState([]);
  const [showDetailsPromotion, setShowDetailsPromotion] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState({
    status: 1,
    idPromocao: '',
    dtInicio: startDate,
    dtFim: endDate,
    termo: '',
  });

  const headerFilter = React.createRef(null);

  useEffect(() => {
    const updateColumns = () => {
      setResolution(window.innerWidth);
    };
    window.addEventListener('resize', updateColumns);
    updateColumns();
    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters]);

  const onClickAddPromotion = async () => {
    try {
      setSearching(true);
      const response = await promocoesScantechService.getNewPromotions();

      if (response != null && response.notifications != null && response.notifications.length > 0) {
        var qtdNotify = response.notifications.length;
        var msg = '';
        for (var i = 0; i < qtdNotify; i++) {
          msg += response.notifications[i].message + '\n';
        }

        notify(msg, 'error', 5000);
      } else if (response) {
        if (response.result.length > 0) {
          setResultData(response.result);
          notify('Novas promoções encontradas com sucesso', 'success', 2000);
        } else {
          notify('Não existem novas promoções', 'error', 2000, 'top');
        }
      } else {
        notify(response.message, 'error', 5000);
      }
    } catch (error) {
      notify('Ocorreu um erro intern. Já estamos atuando.', 'error', 2000, 'top');
    } finally {
      setSearching(false);
    }
  };

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    await request(appliedFilters);

    updateReportInfo();
  };

  const request = async model => {
    setSearching(true);

    try {
      const response = await promocoesScantechService.getPromotions(model);

      if (response != null && response.notifications != null && response.notifications.length > 0) {
        var qtdNotify = response.notifications.length;
        var msg = '';
        for (var i = 0; i < qtdNotify; i++) {
          msg += response.notifications[i].message + '\n';
        }

        notify(msg, 'error', 5000);
      } else {
        if (response && response.result) {
          setResultData(response.result);
          setShowResult(true);
        } else {
          notify(response.message, 'error', 5000);
        }
      }
    } catch (error) {
      notify('Ocorreu um erro intern. Já estamos atuando.', 'error', 2000, 'top');
    } finally {
      setSearching(false);
    }
  };

  const onValueChangedDate = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.fieldName]: event.fieldName === 'dtFim' ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59' : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00',
        };
      });
      setDisabledSearch(false);
    }
  };

  const onValueChanged = event => {
    setAppliedFilters(filters => {
      return {
        ...filters,
        [event.fieldName]: event.value,
      };
    });
  };

  const onAcceptPromotions = async () => {
    try {
      if (resultData.length === 0) {
        notify('Não existem promoções para selecionar', 'info', 2000);
        return;
      }
      if (selectPromotionsGrid.length === 0) {
        notify('Selecione ao menos uma promoção', 'info', 2000);
        return;
      }

      setSearching(true);
      const data = {
        promocoes: selectPromotionsGrid,
      };
      const response = await promocoesScantechService.acceptPromotions(data);

      if (response != null && response.notifications != null && response.notifications.length > 0) {
        var qtdNotify = response.notifications.length;
        var msg = '';
        for (var i = 0; i < qtdNotify; i++) {
          msg += response.notifications[i].message + '\n';
        }

        notify(msg, 'error', 5000);
      } else {
        if (response && response.result) {
          setSelectPromotionsGrid([]);
          setGridBoxValue([]);
          notify('Promoções aceitas', 'success', 2000);
          find();
        } else {
          notify(response.message, 'error', 5000);
        }
      }
    } catch (error) {
      notify('Ocorreu um erro intern. Já estamos atuando.', 'error', 2000, 'top');
    } finally {
      setSearching(false);
    }
  };

  const onRejectPromotions = async () => {
    try {
      if (resultData.length === 0) {
        notify('Não existem promoções para selecionar', 'info', 2000);
        return;
      }
      if (selectPromotionsGrid.length === 0) {
        notify('Selecione ao menos uma para recusar', 'info', 2000);
        return;
      }

      setSearching(true);
      const data = {
        promocoes: selectPromotionsGrid,
      };
      const response = await promocoesScantechService.rejectPromotions(data);

      if (response != null && response.notifications != null && response.notifications.length > 0) {
        var qtdNotify = response.notifications.length;
        var msg = '';
        for (var i = 0; i < qtdNotify; i++) {
          msg += response.notifications[i].message + '\n';
        }

        notify(msg, 'error', 5000);
      } else {
        if (response && response.result) {
          setSelectPromotionsGrid([]);
          setGridBoxValue([]);
          notify('Promoções Rejeitadas com sucesso.', 'success', 2000);
          find();
        } else {
          notify(response.message, 'error', 5000);
        }
      }
    } catch (error) {
      notify('Ocorreu um erro intern. Já estamos atuando.', 'error', 2000, 'top');
    } finally {
      setSearching(false);
    }
  };

  const clearFilters = () => {
    setAppliedFilters({
      status: 1,
      idPromocao: '',
      dtInicio: new Date().toISOString(),
      dtFim: new Date().toISOString(),
    });

    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const updateReportInfo = () => {
    const { dtInicio, dtFim, status, idPromocao } = appliedFilters;
    const nameStatus = statusPromotions.find(x => x.id === status).nome;

    const msgData = `Filtrado por Data entre ${moment(dtInicio).format('DD-MM-YYYY')} e ${moment(dtFim).format('DD-MM-YYYY')}`;
    const msgStatus = `, Status ${nameStatus}`;
    const msgIdPromocao = idPromocao.length > 0 ? `, Id Promoção ${idPromocao}` : '';
    setTitle(msgData + msgStatus + msgIdPromocao);
  };

  const dataGrid_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(operacao => {
      ids.push(operacao);
    });
    setGridBoxValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);

    setSelectPromotionsGrid(ids);
  };

  const handleShowDetails = () => {
    if(resultData.length === 0){
      notify('Não existem promoções para selecionar', 'info', 2000);
      return;
    }

    if (selectPromotionsGrid.length === 0) {
      notify('Selecione ao menos uma promoção para visualizar', 'info', 2000);
      return;
    }

    if (selectPromotionsGrid.length > 1) {
      notify('Selecione somente 1 registro', 'info', 2000);
      return;
    }
    setShowDetailsPromotion(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsPromotion(false);
  };

  const onExporting = useCallback(
    async e => {
      if (e.format === 'xlsx') {
        await new ExportarParaExcelService().execute(e, 'Relatório Scanntech', '', 'Relatorio_Scanntech');
      } else {
        await new ExportarParaPdfService().execute(
          e,
          'Relatório Scanntech',
          `Filtro de ${new Date(appliedFilters.dtInicio).toLocaleDateString()} até ${new Date(appliedFilters.dtFim).toLocaleDateString()}`,
          'Relatorio_Scanntech',
        );
      }

      e.cancel = true;
    },
    [appliedFilters.dtInicio, appliedFilters.dtFim],
  );

  return (
    <LinxPostos className={styles.container} breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message="Carregando..." showPane={true} />
      <Header headerFilter={headerFilter} onClickAddPromotion={onClickAddPromotion} />
      <div className="card">
        <div className="card-body">
          <div className="card">
            <div className="border pl-0 card-body">
              <div className="row">
                <div className="col-md-3">
                  <DropboxStatus placeholder="Status Promoções no Sistema" value={appliedFilters.status} onChange={onValueChanged} id="status" />
                </div>
                <div className="col-md-3">
                  <FloatDateInput displayFormat="dd/MM/yyyy" placeholder="Data Início" label="Data Início" value={appliedFilters.dtInicio} id="dtInicio" onChange={onValueChangedDate} />
                </div>
                <div className="col-md-3">
                  <FloatDateInput displayFormat="dd/MM/yyyy" placeholder="Data Fim" label="Data Fim" value={appliedFilters.dtFim} id="dtFim" onChange={onValueChangedDate} />
                </div>
                <div className="col-md-3">
                  <FloatLabelInput type="text" value={appliedFilters.idPromocao} onChange={onValueChanged} id="idPromocao" label="Id Promoção" />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-3">
                  <FloatLabelInput type="text" value={appliedFilters.termo} onChange={onValueChanged} id="termo" label="Titulo ou Descrição da Promoção" />
                </div>
                <div className="col-md-9 d-flex justify-content-end align-items-end">
                  <button onClick={find} className="btn btn-primary mx-2">
                    <i className="icon icon-lx-search"></i> APLICAR FILTRO
                  </button>
                  <button onClick={clearFilters} className="btn btn-secondary">
                    <i className="icon lg icon-lx-close"></i> LIMPAR
                  </button>
                </div>
              </div>

              <span className="text-center" style={{ margin: '0 auto', fontSize: '12px', color: 'gray', marginTop: '10px', marginBottom: '10px' }}>
                {title}
              </span>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-auto">
              <button onClick={onAcceptPromotions} className="btn btn-primary" style={{ position: 'relative', zIndex: 10 }}>
                <i className="icon icon-lx-doc"></i> ACEITAR
              </button>
            </div>
            <div className="col-auto">
              <button onClick={onRejectPromotions} className="btn btn-primary" style={{ position: 'relative', zIndex: 10 }}>
                <i className="icon icon-lx-doc"></i> REJEITAR
              </button>
            </div>
            <div className="col-auto">
              <button onClick={handleShowDetails} className="btn btn-primary" style={{ position: 'relative', zIndex: 10 }}>
                <i className="icon icon-lx-doc"></i> VISUALIZAR
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className={`${styles.table}`} style={{ position: 'relative', top: '-60px' }}>
            <DataGrid
              rowAlternationEnabled={true}
              dataSource={resultData || [].itens}
              noDataText="Sem dados"
              keyExpr="id"
              id="dataGrid"
              allowColumnReordering={true}
              remoteOperations={true}
              selectedRowKeys={gridBoxValue}
              onSelectionChanged={dataGrid_onSelectionChanged}
              onExporting={onExporting}
              className={`${styles.middle}`}
              allowColumnResizing={true}
            >
              <Selection mode="multiple" />
              <Column dataField="id" width={100} caption="Número da Promoção"></Column>
              <Column dataField="titulo" width="100%" caption="Título Promoção"></Column>
              <Column dataField="descricao" width="100%" caption="Descrição Promoção" visible={resolution > 800}></Column>
              <Column dataField="dataInicio" width={150} visible={resolution > 800} caption="Data Ínicio" dataType="date" format={'dd/MM/yyyy'} />
              <Column dataField="dataFim" width={150} visible={resolution > 800} caption="Data Fim" dataType="date" format={'dd/MM/yyyy'} />
              <Column dataField="status" caption="Status" width={150}></Column>
              <Column dataField="autor" caption="Autor" width={150}></Column>
              <Pager showInfo={true} />
              <Paging defaultPageSize={20} defaultPageIndex={0} />
              <Export
                enabled={true}
                texts={{ exportAll: 'Exporte tudo para {0}', exportSelectedRows: 'Exporte a seleção para {0}', exportTo: 'Exporte para {0}' }}
                formats={['pdf', 'xlsx']}
                fileName={'Ralatório Scanntech'}
              />
            </DataGrid>
          </div>
        </div>
      </div>
      <ModalDetailPromotion active={showDetailsPromotion} handleCloseModal={handleCloseDetails} selectPromotionsGrid={selectPromotionsGrid} setSearching={setSearching} />
    </LinxPostos>
  );
}
