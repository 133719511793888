import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid } from 'devextreme-react';
import { Button as ButtonGrid, Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import { InputText } from '../../../../components/formulario/Formulario';
import { InputCurrency } from '../../../../components/inputCurrency/inputCurrency'; //InputText
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { normalizeToBoolean } from '../../../../shared/utils/Utils';
import DropBoxRegrasParcelamento from '../../consignado-comodato-nova-os/components/drop-box-regras-parcelamentos/dropBoxRegrasParcelamento';
import styles from './AlterarPagamentoPedido.module.scss';
import { AlterarPagamentoPedidoService } from './AlterarPagamentoPedido.service';

function AlterarPagamentoPedido({ turno, data, pagamento: meioPagamento, comboPagamentos, onClose, onUnBusy, onBusy, afterSavePagamento }) {
  //utilizado no carregamento das combos
  let opcaoSelecione = -999;
  const [paramUtilizaCreditoParcelado, setParamUtilizaCreditoParcelado] = React.useState(null);
  const parametro604 = useSelector(selectConsignadoComodatoSharedData).parametro604;

  //os pagamentos da opção clicada
  const pAtuais = turno.pagamentos.filter(x => x.pedidoId === data.pedidoId);

  const pagamentosAtuais = useMemo(
    () =>
      pAtuais.map(p => {
        return {
          pagamentoOriginal: true,
          pagamentoOriginalId: p.pagamentoPedidoId,
          formaPagamento: p.codigoFormaPagamento,
          formaPagamentoDescricao: p.descricaoFormaPagamento,
          redeCartao: p.codigoRedeCartao,
          redeCartaoDescricao: p.descricaoRedeCartao,
          bandeira: p.codigoBandeiraCartao,
          bandeiraDescricao: p.descricaoBandeiraCartao,
          produtoRedeCartao: p.codigoProdutoCartao,
          produtoRedeCartaoDescricao: p.descricaoProdutoCartao,
          valor: p.valor,
          gerouRemessa: p.gerouArquivoRemessa,
          liquidado: p.liquidado,
          codigoNsu: p.codigoNsu,
          centroMonetario: p.codigoCentroMonetario,
          centroMonetarioDescricao: p.centroMonetarioDescricao,
          dataVencimento: p.dataVencimento,
        };
      }),
    [pAtuais],
  );

  const [gridData, setGridData] = useState([]);

  const [pagamento, setPagamento] = useState({
    formaPagamento: '',
    formaPagamentoDescricao: '',
    redeCartao: '',
    redeCartaoDescricao: '',
    bandeira: '',
    bandeiraDescricao: '',
    produtoRedeCartao: '',
    produtoRedeCartaoDescricao: '',
    codigoNsu: '',
    dataVencimento: '',
    centroMonetario: '',
    centroMonetarioDescricao: '',
  });

  useState(() => {
    setPagamento({ ...meioPagamento });
  }, [meioPagamento]);

  async function GetFormas() {
    const responsePagamentos = await AlterarPagamentoPedidoService.GetFormas();
    if (responsePagamentos.notifications && responsePagamentos.notifications.length > 0) {
      responsePagamentos.notifications.forEach(notification => {
        notify(notification.message, 'info', 4000, 'top');
      });
    }
    if (!responsePagamentos.result) notify('Não foram encontradas informações de pagamentos disponíveis no sistema.', 'error', 4000, 'top');
    else if (!responsePagamentos.result.formaPagamentos.length || !responsePagamentos.result.redeCartoes.length) {
      if (!responsePagamentos.result.formaPagamentos.length) notify('Não foram encontradas informações de formas de pagamentos disponíveis no sistema.', 'error', 4000, 'top');

      if (!responsePagamentos.result.redeCartoes.length) notify('Não foram encontradas informações de cartões disponíveis no sistema.', 'error', 4000, 'top');
    } else {
      setFormasPagamento(responsePagamentos.result);
      setFormasUsamCartao(responsePagamentos.result.formaPagamentos.filter(x => x.tipoFormaPagamento.usaCartao).map(x => x.id));
    }
  }

  const [formasUsamCartao, setFormasUsamCartao] = useState([]);

  //para preencher as combos
  const [formasPagamento, setFormasPagamento] = useState([]);

  useEffect(() => {
    if (paramUtilizaCreditoParcelado !== null) return;
    if (!parametro604) return;
    setParamUtilizaCreditoParcelado(normalizeToBoolean(parametro604.valor));
  }, [paramUtilizaCreditoParcelado, parametro604]);

  useEffect(() => {
    //ideia inicial caso a tela que chame envie os dados para a combo,
    if (comboPagamentos) {
      setFormasPagamento(comboPagamentos);
      setFormasUsamCartao(comboPagamentos.formaPagamentos.filter(x => x.tipoFormaPagamento.usaCartao).map(x => x.id));
    } else {
      GetFormas();
    }
    setPagamento({ ...meioPagamento });
  }, [meioPagamento, comboPagamentos]);

  useEffect(() => {
    if (gridData.length > 0) return;
    setGridData(pagamentosAtuais);
  }, [pagamentosAtuais, gridData]);

  const handleButtonClick = () => {
    //#region validações gerais
    if (!pagamento.formaPagamento || pagamento.formaPagamento === opcaoSelecione) {
      notify('Deve ser informada a forma de pagamento', 'error', 4000, 'top');
      return;
    }
    if (!pagamento.valor || pagamento.valor === 0) {
      notify('Deve ser informado o valor da forma de pagamento', 'error', 4000, 'top');
      return;
    }
    let dataVencimento = null;
    if (pagamento.dataVencimento) {
      dataVencimento = new Date(pagamento.dataVencimento);
      dataVencimento.setHours(0, 0, 0, 0);
    }

    const dataAbertura = new Date(turno.dataAbertura);
    dataAbertura.setHours(0, 0, 0, 0);

    if (!dataVencimento || dataVencimento < dataAbertura) {
      notify('Deve ser informada uma data de vencimento válida para o pagamento', 'error', 2000, 'top');
      return;
    }
    //#endregion validações gerais

    //#region validações de boleto
    if (pagamento.formaPagamento === -15) {
      if (!pagamento.centroMonetario || pagamento.centroMonetario === opcaoSelecione) {
        notify('Para pagamentos em boleto é necessário informar o centro monetário', 'error', 4000, 'top');
        return;
      }
    }
    //#endregion validações de boleto

    var tipoFormaPagamento = formasPagamento.formaPagamentos.find(x => x.id === parseInt(pagamento.formaPagamento, 10));
    if (tipoFormaPagamento.id === 17) {
      if (!pagamento.centroMonetario || pagamento.centroMonetario === opcaoSelecione) {
        notify('Para pagamentos em pix é necessário informar o centro monetário', 'error', 4000, 'top');
        return;
      }
    }

    //#region validações de cartão
    if (formasUsamCartao.includes(Number(pagamento.formaPagamento))) {
      if (!pagamento.redeCartao || pagamento.redeCartao === opcaoSelecione) {
        notify('Para pagamentos que possuem transação de cartão deve ser informada a rede', 'error', 4000, 'top');
        return;
      }
      if (!pagamento.bandeira || pagamento.bandeira === opcaoSelecione) {
        notify('Para pagamentos que possuem transação de cartão deve ser informada a bandeira', 'error', 4000, 'top');
        return;
      }

      if (!pagamento.produtoRedeCartao || pagamento.produtoRedeCartao === opcaoSelecione) {
        notify('Para pagamentos que possuem transação de cartão deve ser informado o produto da rede', 'error', 4000, 'top');
        return;
      }
      //ainda não implementado
      if (!pagamento.codigoNsu || pagamento.codigoNsu === '') {
        notify('Para pagamentos que possuem transação de cartão deve ser informado o número do NSU', 'error', 4000, 'top');
        return;
      }
    }
    //#endregion validações de cartão

    setGridData([...gridData, pagamento]);
    //adicionou a grid, volta as combos para opção de selecione
    setPagamento({
      formaPagamento: opcaoSelecione,
      redeCartao: opcaoSelecione,
      bandeira: opcaoSelecione,
      produtoRedeCartao: opcaoSelecione,
    });
  };

  const handleFormaPagamentoChange = event => {
    const itemSelecionado = event.target.options[event.target.selectedIndex];
    setPagamento({ ...pagamento, formaPagamento: itemSelecionado.value, formaPagamentoDescricao: itemSelecionado.innerText });
  };
  const handleRedeCartaoChange = event => {
    const itemSelecionado = event.target.options[event.target.selectedIndex];
    const valorItemSelecionado = parseInt(itemSelecionado.value, 10);
    setPagamento({ ...pagamento, redeCartao: valorItemSelecionado, redeCartaoDescricao: itemSelecionado.innerText });
  };

  const handleBandeiraChange = event => {
    const itemSelecionado = event.target.options[event.target.selectedIndex];
    setPagamento({ ...pagamento, bandeira: itemSelecionado.value, bandeiraDescricao: itemSelecionado.innerText });
  };

  const handleProdutoRedeCartaoChange = event => {
    const itemSelecionado = event.target.options[event.target.selectedIndex];
    setPagamento({ ...pagamento, produtoRedeCartao: itemSelecionado.value, produtoRedeCartaoDescricao: itemSelecionado.innerText });
  };

  const handleRegrasParcelamentoSelected = data => {
    setPagamento({ ...pagamento, regraParcelamento: { regraParcelamentoId: data.regraParcelamentoId, parcelas: data.qtParcelas } });
  };

  function handleRemoverPagamento(data) {
    //passa o item para excluído para envio ao server
    const newData = gridData.map(item => (item === data.row.data ? { ...item, excluido: true } : item));
    setGridData(newData);
  }

  const handleNsu = event => {
    setPagamento({ ...pagamento, codigoNsu: event.target.value });
  };

  const handleCentroMonetarioChange = event => {
    const itemSelecionado = event.target.options[event.target.selectedIndex];
    setPagamento({ ...pagamento, centroMonetario: itemSelecionado.value, centroMonetarioDescricao: itemSelecionado.innerText });
  };
  const handleDataVencimento = event => {
    const newDate = event.value;
    if (newDate) {
      setPagamento({ ...pagamento, dataVencimento: newDate });
    }
  };

  const handleCancel = () => {
    if (typeof onClose === 'function') onClose();
  };

  const handleSave = async () => {
    if (typeof onBusy === 'function') onBusy('Valdidando informações a serem salvas...');
    //pega a soma dos pagamentos atuais para ver se o novo total confere com o total antigo
    const valorTotalAnterior = pagamentosAtuais
      .reduce((acumulador, objetoAtual) => {
        return acumulador + objetoAtual.valor;
      }, 0)
      .toFixed(2);
    const valorTotalInformado = gridData
      .reduce((acumulador, objetoAtual) => {
        if (objetoAtual.excluido) return acumulador + 0;
        else return acumulador + objetoAtual.valor;
      }, 0)
      .toFixed(2);
    if (valorTotalAnterior !== valorTotalInformado) {
      notify(
        'O valor total dos pagamentos informados ' +
          valorTotalInformado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) +
          ' difere do valor original ' +
          valorTotalAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        'error',
        4000,
        'top',
      );
      if (typeof onUnBusy === 'function') onUnBusy();
      return;
    }
    const pedido = {
      pedidoId: data.pedidoId,
      pedidoNumero: data.numeroPedido,
      versao: data.versao,
      clienteId: data.codigoCliente,
      turnoId: data.turnoId,
      pagamentos: gridData.map(pagamento => {
        return {
          redeCartao: pagamento.redeCartao,
          bandeira: pagamento.bandeira,
          produtoRedeCartao: pagamento.produtoRedeCartao,
          codigoNsu: pagamento.codigoNsu ? String(pagamento.codigoNsu) : null /*o C# não faz o cast para string, deixando o objeto todo vazio*/,
          centroMonetario: pagamento.centroMonetario,
          formaPagamento: pagamento.formaPagamento,
          gerouRemessa: pagamento.gerouRemessa || false,
          liquidado: pagamento.liquidado || false,
          pagamentoOriginalId: pagamento.pagamentoOriginalId,
          pagamentoOriginal: pagamento.pagamentoOriginal || false,
          excluido: pagamento.excluido || false,
          dataVencimento: pagamento.dataVencimento,
          valor: pagamento.valor.toFixed(2),
          regraParcelamento: pagamento.regraParcelamento,
        };
      }),
    };

    if (typeof onUnBusy === 'function') onUnBusy();
    if (typeof onBusy === 'function') onBusy('Salvando alterações...');
    const listaPedidos = [pedido];
    let ret = await AlterarPagamentoPedidoService.SalvaTurnoPedidosPagamentos(listaPedidos);
    if (!ret || (!ret.result && !ret.notifications)) {
      notify('Ocorreu um erro inesperado ao processar a solicitação, tente novamente mais tarde.', 'error', 4000, 'top');
      if (typeof onUnBusy === 'function') onUnBusy();
      return;
    }
    if (ret.notifications.length > 0) {
      ret.notifications
        .filter(x => x.NotificationLevel !== 1)
        .forEach(notification => {
          notify(notification.message, 'error', 4000, 'top');
        });
      if (typeof onUnBusy === 'function') onUnBusy();
      return;
    }
    //se props tiver método próprio para recarrgar, chamar
    notify('Alteração realizada com sucesso', 'success', 4000, 'top');
    if (typeof onUnBusy === 'function') onUnBusy();
    if (typeof afterSavePagamento === 'function') afterSavePagamento(data.turnoId);
  };

  if (!pagamento) return <></>;
  return (
    <Card>
      <Card.Header style={{ backgroundColor: '#5b2e90', color: 'white' }}>Alteração de Forma de Pagamento</Card.Header>
      <Card.Body>
        <Form>
          <Form.Group as={Row}>
            <Col sm="6">
              <Form.Label>Forma de pagamento</Form.Label>
              <Form.Control as="select" value={pagamento.formaPagamento} onChange={handleFormaPagamentoChange}>
                <option value={opcaoSelecione} selected>
                  SELECIONE
                </option>
                {formasPagamento &&
                  formasPagamento.formaPagamentos &&
                  formasPagamento.formaPagamentos.map(forma => (
                    <option key={forma.id} value={forma.id}>
                      {forma.descricao}
                    </option>
                  ))}
              </Form.Control>

              {formasUsamCartao.includes(Number(pagamento.formaPagamento)) ? (
                <>
                  <Form.Label>Rede</Form.Label>
                  <Form.Control as="select" value={pagamento.redeCartao} onChange={handleRedeCartaoChange}>
                    <option value={opcaoSelecione} selected>
                      SELECIONE
                    </option>
                    {formasPagamento.redeCartoes.map(rede => (
                      <option key={rede.codigoRedeCartao} value={rede.codigoRedeCartao}>
                        {rede.descricaoRedeCartao}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Label>Cartão / Bandeira</Form.Label>
                  <Form.Control as="select" value={pagamento.bandeira} onChange={handleBandeiraChange}>
                    <option value={opcaoSelecione} selected>
                      SELECIONE
                    </option>
                    {formasPagamento.bandeiras.map(bandeira => (
                      <option key={bandeira.codigoBandeira} value={bandeira.codigoBandeira}>
                        {bandeira.descricaoBandeira}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Label>Produto</Form.Label>
                  <Form.Control as="select" value={pagamento.produtoRedeCartao} onChange={handleProdutoRedeCartaoChange}>
                    <option value={opcaoSelecione} selected>
                      SELECIONE
                    </option>
                    {formasPagamento.redeCartoes.find(rede => rede.codigoRedeCartao === pagamento.redeCartao)
                      ? formasPagamento.redeCartoes
                          .find(rede => rede.codigoRedeCartao === pagamento.redeCartao)
                          .produtoRedeCartao.map(produto => (
                            <option key={produto.codigoProdutoRedeCartao} value={produto.codigoProdutoRedeCartao}>
                              {produto.descricaoProdutoRedeCartao}
                            </option>
                          ))
                      : null}
                  </Form.Control>
                  {['-4'].includes((pagamento || {}).formaPagamento) && paramUtilizaCreditoParcelado && (
                    //colocado, -1 em produtoRede, redeCod, e bancCod proposital
                    //pois na conferência de turno só pode a vista, então não vai ler a regra de parcelamento na estrutura de cartões
                    <DropBoxRegrasParcelamento
                      produtoRede={-1}
                      redeCod={-1}
                      bandCod={-1}
                      valorPagamento={(pagamento || {}).valorPagamento}
                      onSelected={handleRegrasParcelamentoSelected}
                    />
                  )}
                </>
              ) : null}
            </Col>
            <Col sm="6">
              {formasUsamCartao.includes(Number(pagamento.formaPagamento)) ? (
                <>
                  <Form.Label>NSU</Form.Label>
                  <InputGroup className="mb-3">
                    <InputText
                      value={pagamento.codigoNsu || 0}
                      onChange={handleNsu}
                      className="form-control text-right"
                      name="codigoNsu"
                      placeholder="0"
                      type="integer"
                      max={
                        2147483647
                      } /*tem alguma tabela que trata o nsu como int! mas não achei qual é ainda
                      na tgFinTitu usa esse campo como varchar (14) no titunum (right) (mesmo mudando o varchar ainda estoura),
                      na tgConComodatoPagamentoInfo e a tgFisVendPagaTef como bigint
                      */
                    />
                  </InputGroup>
                </>
              ) : null}

              {Number(
                pagamento.formaPagamento === -15 ||
                  (((formasPagamento.formaPagamentos || []).find(x => x.id === parseInt(pagamento.formaPagamento || '0', 10)) || {}).tipoFormaPagamento || {}).id === 17,
              ) ? (
                <>
                  <Form.Label>Centro monetário</Form.Label>
                  <Form.Control as="select" value={pagamento.centroMonetario} onChange={handleCentroMonetarioChange}>
                    <option value={opcaoSelecione} selected>
                      SELECIONE
                    </option>
                    {formasPagamento.centrosMonetarios
                      .filter(
                        centro =>
                          centro.ativo &&
                          !centro.excluido &&
                          centro.banco.ativo &&
                          centro.numeroAgencia !== '0' &&
                          centro.numeroAgencia !== '' &&
                          (centro.codigoBanco !== 0 || centro.codigoBanco !== 8888 || centro.codigoBanco !== 9999),
                      )
                      .map(centro => (
                        <option key={centro.codigoCentroMonetario} value={centro.codigoCentroMonetario}>
                          {centro.banco.nome + ' (Ag:' + centro.numeroAgencia + ' Cta:' + centro.numeroConta + ')'}
                        </option>
                      ))}
                  </Form.Control>
                </>
              ) : null}

              <Form.Label>Data de vencimento</Form.Label>
              <InputGroup className="mb-3">
                <div style={{ width: '100%' }}>
                  <div className={styles.dateWrapper}>
                    <div className={styles.datebox}>
                      <DateTimeBox
                        type="date"
                        id="DataVencimento"
                        value={pagamento.dataVencimento}
                        height="40px"
                        placeholder="Informe a data de vencimento"
                        onValueChanged={handleDataVencimento}
                        min={new Date(turno.dataAbertura)}
                        dateOutOfRangeMessage="Data fora do período do turno"
                      />
                    </div>
                  </div>
                </div>
              </InputGroup>

              <Form.Label>Valor do pagamento</Form.Label>
              <InputGroup className="mb-3">
                <InputCurrency
                  value={pagamento.valor || 0}
                  onChange={(_, money) => {
                    setPagamento({ ...pagamento, valor: money });
                  }}
                  onBlur={(_, money) => setPagamento({ ...pagamento, valorPagamento: money })}
                  className="form-control text-right"
                  name="valorPagamento"
                  placeholder="R$ 0,00"
                  max={999999.99}
                />
                <Button variant="outline-primary" id="btnAddPagamento" onClick={handleButtonClick}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </InputGroup>
            </Col>
          </Form.Group>
        </Form>
        <div style={{ fontStyle: 'italic' }}>
          {' '}
          As formas de pagamentos deste pedido devem totalizar{' '}
          {pagamentosAtuais
            .reduce((acumulador, objetoAtual) => {
              return acumulador + objetoAtual.valor;
            }, 0)
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}{' '}
        </div>
        <br />
        <DataGrid dataSource={(gridData || []).filter(x => !x.excluido)} showRowLines={true} showColumnLines={false} columnAutoWidth={true}>
          <Column dataField="formaPagamentoDescricao" caption="Forma de pagamento"></Column>
          <Column dataField="redeCartaoDescricao" caption="Rede"></Column>
          <Column dataField="bandeiraDescricao" caption="Bandeira"></Column>
          <Column dataField="produtoRedeCartaoDescricao" caption="Produto"></Column>
          <Column dataField="codigoNsu" caption="NSU"></Column>
          <Column dataField="centroMonetarioDescricao" caption="Centro monetário"></Column>
          <Column dataField="dataVencimento" caption="Data de vencimento" dataType="date"></Column>
          <Column dataField="valor" caption="Valor do pagamento R$" format="#,##0.00"></Column>
          <Summary>
            <TotalItem column="valor" summaryType="sum" valueFormat="R$ #,##0.00" displayFormat={'{0}'} />
          </Summary>
          <Column type="buttons" width={50} caption="Ações">
            {' '}
            {/* aqui num futuro taaaaalvez adicionar possibilidade de gerar o boleto tbm num futuro distante */}
            <ButtonGrid hint="Remover pagamento" icon="trash" disabled={data => data.row.data.gerouRemessa || data.row.data.liquidado} onClick={handleRemoverPagamento} />
          </Column>
        </DataGrid>

        <Button variant="danger" className="mr-2" onClick={handleCancel}>
          <FontAwesomeIcon icon={faTimes} /> CANCELAR
        </Button>
        <Button variant="primary" onClick={handleSave}>
          <FontAwesomeIcon icon={faSave} /> SALVAR
        </Button>
      </Card.Body>
      <br />
    </Card>
  );
}

export default AlterarPagamentoPedido;
