import { DropDownBox, List } from 'devextreme-react';
import React, { useCallback, useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import { DropDownImage } from '../../consignado-comodato-nova-os/components/drop-down-image';
import styles from './Component.module.scss';

export const statusPromotions = [
  {
    'id': 1,
    'nome': 'Todas'
  },
  {
    'id': 2,
    'nome': 'Aceitas',
  },
  {
    'id': 3,
    'nome': 'Pendentes',
  },
  {
    'id': 4,
    'nome': 'Rejeitadas',
  }
];

export function DropboxStatus({id, placeholder, value, onChange, statusCss, className, label }) {
  const [opened, setOpened] = useState(false);
  const [selectedItemKey, setSelectedItemKey] = useState([value]);

  useEffect(()=>{
    setSelectedItemKey([value]);
  },[value])

  const onSelectedItemKeyChange = useCallback(
    args => {
      if (args.name === 'selectedItemKeys') {
        if (args.value.join('_') === selectedItemKey.join('_')) return;
        onChange({
          fieldName: id,
          value: Number(args.value[0]),
        });
        setSelectedItemKey(args.value);
      }
    },
    [selectedItemKey, onChange, id],
  );
  return (
    <div className={`${className} mt-1`}>
      <DropDownBox
        id={id}
        showClearButton={false}
        className={`${styles.bottonBorder} ml-1`}
        placeholder={placeholder}
        valueExpr="id"
        displayExpr="nome"
        showDropDownButton={true}
        dropDownButtonRender={DropDownImage}
        dataSource={statusPromotions.filter(x => x.id > -1)}
        openOnFieldClick={true}
        onValueChanged={e => {setOpened(false)}}
        value={selectedItemKey}
        onOpenedChange={e => {
          setOpened(e);
        }}
        opened={opened}
        onKeyUp={e => {
          if (opened) e.preventDefault();
          if (
            e.event.originalEvent.code === 'ArrowDown' ||
            e.event.originalEvent.key === 'ArrowDown' ||
            e.event.originalEvent.code === 'Enter' ||
            e.event.originalEvent.key === 'Enter' ||
            e.event.originalEvent.code === 'Space' ||
            e.event.originalEvent.key === 'Space'
          ) {
            setOpened(true);
          }
        }}
        contentRender={() => {
          return (
            <>
              <Card>
                <Card.Body>
                  <List
                    noDataText="Sem status"
                    keyExpr="id"
                    displayExpr="nome"
                    dataSource={statusPromotions.filter(x => x.id > -1)}
                    checkedExpr="checked"
                    selectionMode={'single'}
                    onOptionChanged={onSelectedItemKeyChange}
                    selectedItemKeys={selectedItemKey}
                  />
                </Card.Body>
                {/* <Card.Footer> */}
                  {/* <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      setOpened(false);
                    }}
                  >
                    OK
                  </button> */}
                {/* </Card.Footer> */}
              </Card>
            </>
          );
        }}
      />
      <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    </div>
  );
}
